const mypxl = mypxl || {};

(function (mypxl, $, window, document, undefined) {
    // private consts
    //
    const _d = document;
    const _b = _d.body;
    const _w = window;


    /**
     * Lazyload solution - vanillaJS
     */
    const doLazyLoad = function () {
        // Lazy Load images
        mypxl.myLazyLoad = new LazyLoad({
            elements_selector: ".lazy",
        });
    };



    /**
     * Carousels for Projects and images
     * uses Slick JS slider
     */
    const doSlick = function doSlickFn() {

        // homepage slider of projects
        $(".featured-projects__slides .slides").slick({
            fade: false,
            slidesToShow: 5,
            dots: false,
            arrows: true,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 6000,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4
                    }
                },
                {
                    breakpoint: 960,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });

        // single project image gallery
        $(".module--project .gallery").slick({
            fade: false,
            slidesToShow: 4,
            dots: false,
            arrows: true,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 6000,
            responsive: [
                {
                    breakpoint: 960,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });

        $(".more-projects__slides .slides").slick({
            fade: false,
            slidesToShow: 5,
            dots: false,
            arrows: true,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 6000,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4
                    }
                },
                {
                    breakpoint: 960,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    };


    /**
     * Mobile Nav
     */
    const doMobileMenu = function () {
        // hamburger
        const _brgr = document.getElementById("js-toggle-menu");
        $(_brgr).on("click", function (e) {
            // toggle active class on button
            $(this).toggleClass("is-active");

            //toggle class on body
            $(_b).toggleClass("mobile-menu-is-active");

            e.preventDefault();
        });
    };



    /**
     * Fixed header classes
     */
    var stickyNavInit = function( doWhat ){

        switch( doWhat ){
            case 'clear' :
                $(_b).removeClass('page-is-scrolled');
                break;

            case 'init' :
                $(_b).addClass('page-is-scrolled');
                break;

            default :
                break;
        }
    };


    /**
     * Sticky navigation class controller
     */
    var doScrollController = function() {    

        $(_d).on('scroll', function() {

            // top of scroll
            if( $(_w).scrollTop() <= 100 ) {

                try {
                    stickyNavInit('clear');
                } catch(error) {
                    return;
                }
            }

            if( !$(_b).hasClass('page-is-scrolled') && $(_w).scrollTop() > 100 ) {
                try {
                    stickyNavInit('init');
                } catch(error) {
                    return;
                }
            }

        });

    };

    /**
     * JS for the Filters on the Project page using MixItUp
     */
    var doMixItUp = function doMixItUpFn() {
        var mixer = mixitup( '.projects-list' , {
            selectors: {
                target: '.mix'
            },
            animation: {
                "duration": 250,
                "nudge": true,
                "reverseOut": false,
                "effects": "fade translateZ(-100px)"
            }
        });
    }


    /**
     * Toggles the Careers page job info
     */
    var doCareersToggle = function doCareersToggleFn(){

        $('.positions').on( 'click', '.js-toggle-job', function(e){

            $(this).parents('.position').toggleClass('active');

            e.preventDefault();

        });
    }



    /**
     * Toggles the Team page member bio
     */
    var doTeamMemberBioToggle = function doTeamMemberBioToggleFn(){

        // all the team
        const allTeamMembers = $('.team-member');

        $('.member-wrap').on( 'click', '.js-toggle-bio', function(e){
            
            // cache current members parent wrapper
            const currentMemberParent = $(this).parents('.team-member');

            // remove active from any other currently active member
            allTeamMembers.not( currentMemberParent ).removeClass('bio-active');

            // toggle clicked team members bio
            currentMemberParent.toggleClass('bio-active');
            
            e.preventDefault();

        });
    }







    /**
     * jQuery onReady
     */
    $(function () {

        doScrollController();

        doLazyLoad();

        doMobileMenu();

        doSlick();

        //filtering for projects
        if( $('.projects-list').length ) {
            doMixItUp();
        }

        // careers toggle info on positions
        if( $('.positions').length ) {
            doCareersToggle();
        }

        // Team toggle info on bios
        if( $('.module--team_members').length ) {
            doTeamMemberBioToggle();
        }





        /**
         * animated hightlights on home page
         *  counts up from '0' to specified number 
         */
        var animatedElementWrap = document.getElementById('js-animatedHighlights');
        if( $(animatedElementWrap).length ) {
            
            var elementHeight = animatedElementWrap.clientHeight;

            // listen for scroll event and call animate function once we get to animatedElementWrap as selected above
            document.addEventListener('scroll', animate);

            // check if animatedElementWrap is in view
            function inView() {
                // get window height
                var windowHeight = window.innerHeight;
                // get number of pixels that the document is scrolled
                var scrollY = window.scrollY || window.pageYOffset;
                
                // get current scroll position (distance from the top of the page to the bottom of the current viewport)
                var scrollPosition = scrollY + windowHeight;
                // get animatedElementWrap position (distance from the top of the page to the bottom of the animatedElementWrap)
                var elementPosition = animatedElementWrap.getBoundingClientRect().top + scrollY + elementHeight;
                
                // is scroll position greater than element position? (is element in view?)
                if (scrollPosition > elementPosition) {
                    return true;
                }
                
                return false;
            }

            // animate element when it is in view
            function animate() {

                // is element in view?
                if (inView()) {
                    
                    // once is enough...
                    document.removeEventListener('scroll', animate);
                    
                    $('.js-counterAnimate').each(function () {
                        var $this = $(this);
                        $({ Counter: 0 }).animate({ Counter: $this.attr('data-count-to') }, {
                            duration: 1250,
                            easing: 'swing',
                            step: function () {
                                $this.text(Math.ceil(this.Counter));
                            }
                        });
                    });
                }
            }
        }


    });
})(mypxl, jQuery, window, document);
